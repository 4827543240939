// hooks/useDevice.ts
import { useState, useEffect } from "react";

const useDevice = () => {
  const userAgent =
    navigator.userAgent || navigator.vendor || (window as any).opera;
  const detectDevice = () => {
    if (/windows phone/i.test(userAgent)) {
      return "Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Phone";
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
      return "Phone";
    }

    if (/Macintosh/.test(userAgent) && "ontouchend" in document) {
      return "Desktop";
    }

    return "Desktop";
  };
  const [device, setDevice] = useState<string>("");

  useEffect(() => {
    setDevice(detectDevice());
  }, [userAgent]);

  return { device };
};

export default useDevice;
