import React, { lazy, Suspense, useMemo, useRef, useState } from "react";
import { Canvas } from "@react-three/fiber";
import { FaceLandmarker } from "@react-three/drei";
import Loader from "./components/loader";
import useDevice from "./hooks/detect-device";
import { ArtData } from "./assets";
import * as THREE from "three";
const Model = lazy(() => import("./components/model"));
const Screen = lazy(() => import("./components/board"));
const SpotLight = lazy(() => import("./components/spot-light"));
const Face = lazy(() => import("./components/face"));
const HintComponent = lazy(() => import("./components/hint"));
const GenesisCard = lazy(() => import("./components/genesis-card"));
const MobileCotroller = lazy(() => import("./components/mobile-controller"));
const Controls = lazy(() => import("./components/controls"));
function App() {
  const { device } = useDevice();
  const [currentArtModalData, setCurrentArtModalData] = useState<any>();
  const [hintModal, setHintModal] = useState(false);
  const [moveForward, setMoveForward] = useState(false);
  const [moveBackward, setMoveBackward] = useState(false);
  const [moveLeft, setMoveLeft] = useState(false);
  const [moveRight, setMoveRight] = useState(false);
  const [progress, setProgress] = useState(0);

  return (
    <Suspense fallback={<Loader progress={progress} />}>
      {hintModal ? <HintComponent /> : null}
      {currentArtModalData ? (
        <GenesisCard
          data={currentArtModalData}
          setCurrentArtModalData={setCurrentArtModalData}
        />
      ) : null}
      {device == "Phone" ? (
        <MobileCotroller
          moveForward={moveForward}
          setMoveForward={setMoveForward}
          moveBackward={moveBackward}
          setMoveBackward={setMoveBackward}
          moveLeft={moveLeft}
          setMoveLeft={setMoveLeft}
          moveRight={moveRight}
          setMoveRight={setMoveRight}
        />
      ) : null}
      <Canvas
        shadows
        dpr={[1, 1.5]}
        camera={{ position: [-8, 2, -7], fov: 65 }}
        eventSource={document.getElementById("root")}
        eventPrefix="client"
        style={{ zIndex: "1" }}
      >

          <ambientLight intensity={0.3} />

          <Model
            path="/model/model_Compressed-Ultimate.gltf"
            onLoad={() => setHintModal(true)}
            onChangeProgress={(progress) => setProgress(progress)}
          />
          {/* david light */}
          <SpotLight position={[5, 4, 0]} targetPosition={[6.5, 2, 0]} />
          {/* Iranian Illumination */}
          <SpotLight
            position={[-1, 3.5, -1.25]}
            targetPosition={[-0.5, 1, -1.25]}
          />
          <Screen
            data={ArtData[8]}
            position={[-0.75, 1.7, -1.25]}
            rotation={[0, -7.85, 0]}
            scale={[1, 1, 4]}
            setCurrentArtModalData={setCurrentArtModalData}
          />
          {/* A River of Mercy: Surah Kawsar in Kufi Qirvani Majesty */}
          <SpotLight
            position={[-1, 3.5, 1.25]}
            targetPosition={[-0.5, 1, 1.25]}
          />
          <Screen
            data={ArtData[9]}
            position={[-0.75, 1.7, 1.25]}
            rotation={[0, -7.85, 0]}
            scale={[1, 1.25, 4]}
            setCurrentArtModalData={setCurrentArtModalData}
          />
          {/* "Oil Mirror Frame" */}
          <SpotLight
            position={[-0.1, 3.5, -1.25]}
            targetPosition={[-1.31, 1, -1.25]}
          />
          <Screen
            data={ArtData[1]}
            position={[-0.31, 1.75, -1.25]}
            rotation={[0, 7.85, 0]}
            scale={[1, 1.25, 4]}
            setCurrentArtModalData={setCurrentArtModalData}
          />
          {/* "Spreading Oil Flowers and Shrubs" */}
          <SpotLight
            position={[-0.1, 3.5, 1.25]}
            targetPosition={[-1.31, 1, 1.25]}
          />
          <Screen
            data={ArtData[0]}
            position={[-0.31, 1.75, 1.25]}
            rotation={[0, 7.85, 0]}
            scale={[1, 1.25, 4]}
            setCurrentArtModalData={setCurrentArtModalData}
          />
          {/* wide painting */}
          <SpotLight
            position={[-2.2, 5, -5]}
            targetPosition={[-2.2, 2, -7.4]}
          />
          <Screen
            data={ArtData[2]}
            position={[-2, 2, -7.4]}
            rotation={[0, 0, 0]}
            scale={[7, 2, 4]}
            setCurrentArtModalData={setCurrentArtModalData}
          />
          {/* Bullfighting */}
          <SpotLight position={[4.5, 5, -5]} targetPosition={[4.5, 2, -7.4]} />
          <Screen
            data={ArtData[7]}
            position={[4.5, 2, -7.4]}
            rotation={[0, 0, 0]}
            scale={[2.75, 2, 4]}
            setCurrentArtModalData={setCurrentArtModalData}
          />
          <SpotLight
            position={[-9, 5, -3.8]}
            targetPosition={[-9.9, 2, -3.8]}
          />
          <Screen
            data={ArtData[3]}
            position={[-9.9, 2, -3.8]}
            rotation={[0, 7.9, 0]}
            scale={[2.85, 2, 4]}
            setCurrentArtModalData={setCurrentArtModalData}
          />
          <SpotLight position={[-9, 5, 3.8]} targetPosition={[-9.9, 2, 3.8]} />
          <Screen
            data={ArtData[6]}
            position={[-9.9, 2, 3.8]}
            rotation={[0, 7.9, 0]}
            scale={[2, 2, 4]}
            setCurrentArtModalData={setCurrentArtModalData}
          />
          <SpotLight position={[-5, 5, 6.5]} targetPosition={[-5, 2, 7.4]} />
          <Screen
            data={ArtData[4]}
            position={[-5, 2, 7.4]}
            rotation={[0, -3.1, 0]}
            scale={[2, 2.85, 4]}
            setCurrentArtModalData={setCurrentArtModalData}
          />
          <SpotLight position={[0.5, 5, 6.5]} targetPosition={[0.5, 2, 7.4]} />
          <Screen
            data={ArtData[5]}
            position={[0.5, 2, 7.4]}
            rotation={[0, -3.1, 0]}
            scale={[2.85, 2, 4]}
            setCurrentArtModalData={setCurrentArtModalData}
          />
          {/* Curpet */}
          <SpotLight position={[14.4, 2, 0]} targetPosition={[10.4, 1, 0]} />
          <Screen
            data={ArtData[10]}
            position={[10.4, 1, 0]}
            rotation={[0, 7.85, 0]}
            scale={[4, 6, 4]}
            setCurrentArtModalData={setCurrentArtModalData}
          />
          <SpotLight
            position={[16.5, 5, -6]}
            targetPosition={[16.5, 1, -7.4]}
          />
          <Screen
            data={ArtData[11]}
            position={[16.5, 1, -7.4]}
            rotation={[0, 0, 0]}
            scale={[4, 6, 4]}
            setCurrentArtModalData={setCurrentArtModalData}
          />
          <SpotLight
            position={[16.5, 5, 6]}
            targetPosition={[16.5, 0.9, 7.4]}
          />
          <Screen
            data={ArtData[12]}
            position={[16.5, 0.9, 7.4]}
            rotation={[0, -3.15, 0]}
            scale={[4, 6, 4]}
            setCurrentArtModalData={setCurrentArtModalData}
          />
\
        <Face
          setPointerLocked={setHintModal}
          currentArtModalData={currentArtModalData}
        />

        <Controls
          moveForward={moveForward}
          setMoveForward={setMoveForward}
          moveBackward={moveBackward}
          setMoveBackward={setMoveBackward}
          moveLeft={moveLeft}
          setMoveLeft={setMoveLeft}
          moveRight={moveRight}
          setMoveRight={setMoveRight}
        />
      </Canvas>
    </Suspense>
  );
}

export default App;
