import React from "react";

const Loader = ({progress}) => {

  return (

      <div
        style={{
          textAlign: "center",
          background: "#060425",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          height: "100dvh",
          width: "100lvw",
        }}
      >
        <div style={{ color: "#ffff", marginBottom: "5px" }}>
          We are preparing Artogenesis Room...
        </div>
        <progress
          value={progress}
          max="100"
          style={{
            color: "#cfd8dc", // Change this to your desired color
            WebkitAppearance: "none",
            appearance: "none",
            borderRadius: "12px",
            overflow: "hidden",
            height: "10px",
            width: "300px",
          }}
        >
          {progress}%
        </progress>
        <style>
          {`
              progress::-webkit-progress-bar {
                background-color: #eee;
              }
              progress::-webkit-progress-value {
                background-color: #6A72A0; /* Change this to your desired color */
              }
              progress::-moz-progress-bar {
                background-color: #6A72A0; /* Change this to your desired color */
              }
            `}
        </style>
      </div>
  );
};

export default Loader;
