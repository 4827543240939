const ArtData = [
  {
    artist: "Alireza Ghezi",
    price: 25000,
    mockup: "",
    description:
      "An exquisite botanical masterpiece, ‘Spreading Oil Flowers and Shrubs’ captivates with its vibrant hues and meticulous technique. Measuring 12 by 16.5 cm, this artwork combines gouache and watercolor on a handmade oil-primed canvas. The color palette ranges from warm yellows to deep reds, soft pinks to lush greens, all set against a creamy background. The intricate floral composition showcases lifelike details, while the borders feature geometric patterns in black, red, and gold tones. Created in 2012, this piece exudes elegance and charm, making it a delightful addition for collectors or as a refined decorative item.",
    name: "Spreading Oil Flowers and Shrubs",
    link: "https://artogenia.com/product/6665bd0f3667c4710219ca36",
    imgUrl:
      "https://coctljfofa.cloudimg.io/https://api.artogenia.com/files?id=6665bd0ef0c040964a14d268&force_format=webp&q=20&optipress=3",
  },
  {
    artist: "Alireza Ghezi",
    price: 25000,
    mockup: "",
    description:
      "An exquisite mixed-media masterpiece, the ‘Oil Mirror Frame’ combines acrylic, gouache, and watercolor on a jujube wood frame. Measuring 25 by 36 cm, this artwork exudes opulence and craftsmanship. The rich brown background sets the stage for vibrant floral hues—soft pinks, oranges, and yellows—accented by lush green foliage. The intricate gold patterns on the frame add depth and elegance. A high-gloss varnish coating enhances the luminosity of the colors. Created in 2010, this piece seamlessly blends tradition and contemporary artistry, making it a captivating addition for collectors and interior spaces.",
    name: "Oil Mirror Frame",
    link: "https://artogenia.com/product/6665be893667c4710219ca3a",
    imgUrl:
      "https://coctljfofa.cloudimg.io/https://api.artogenia.com/files?id=6665be89f0c040964a14d26c&force_format=webp&q=20&optipress=3",
  },
  {
    artist: "Ali Seyedani",
    price: 3000,
    mockup: "",
    description:
      "An original acrylic masterpiece, this artwork measures 60 by 90 cm and is skillfully rendered on a wood board. The artist’s adept use of acrylics results in a harmonious interplay of rich colors. Dominant hues of deep blues and earthy browns are subtly accented by greens and muted purples, creating a dynamic visual experience. This piece exudes sophistication and would be an intriguing addition to any collection",
    name: "Untitled",
    link: "https://artogenia.com/product/66901cdfdc7dd6f785f179e7",
    imgUrl:
      "https://coctljfofa.cloudimg.io/https://api.artogenia.com/files?id=66901cdff0c040964a14d896&force_format=webp&q=20&optipress=3",
  },
  {
    artist: "Reza Khalili",
    price: 19500,
    mockup: "",
    description:
      "Measuring an impressive 100 by 150 cm, ‘Collection Of Twilight’ is a captivating contemporary piece rendered in acrylic on canvas. The artist skillfully employs a harmonious blend of muted and vibrant hues, with a dominant palette of soft grays, deep purples, and warm undertones of pink and orange that suggest the fleeting moments of twilight. The technique employed allows for a smooth transition between colors, creating a sense of depth and movement across the canvas. This artwork captures the essence of dusk with its subtle interplay of light and shadow, making it an intriguing addition to any collection or space seeking a touch of serene elegance",
    name: "Collection Of Twilight",
    link: "https://artogenia.com/product/6689871926c9357a649dbb40",
    imgUrl:
      "https://coctljfofa.cloudimg.io/https://api.artogenia.com/files?id=66898719f0c040964a14d74e&force_format=webp&q=20&optipress=3",
  },
  {
    artist: "Reza Khalili",
    price: 19500,
    mockup: "",
    description:
      "Measuring an impressive 150 by 100 cm, ‘Collection Of Twilight’ is a captivating contemporary piece rendered in acrylic on canvas. The artist skillfully employs a harmonious blend of muted and earthy tones that transition smoothly across the canvas. The technique employed allows for a subtle interplay of light and shadow, giving the impression of a natural landscape bathed in the soft glow of dusk. The use of acrylics provides depth and texture to the painting, with brushstrokes that add to the overall dynamic yet serene atmosphere. This artwork would make a sophisticated addition to any collection, appealing to those who appreciate abstract interpretations of natural phenomena.",
    name: "Collection Of Twilight",
    link: "https://artogenia.com/product/66898a9f26c9357a649dbb43",
    imgUrl:
      "https://coctljfofa.cloudimg.io/https://api.artogenia.com/files?id=66898a9ff0c040964a14d750&force_format=webp&q=20&optipress=3",
  },
  {
    artist: "Reza Khalili",
    price: 19500,
    mockup: "",
    description:
      "Measuring an impressive 80 by 120 cm, ‘Collection Of Twilight’ is a captivating contemporary piece rendered in acrylic on canvas. The artist skillfully employs a rich palette dominated by moody hues of grey, brown, and subtle undertones of dusky purple. The technique allows for smooth color gradations, with darker tones seamlessly blended to create depth. The textured application of paint invites closer inspection, while the interplay between light and shadow adds an intriguing atmospheric quality. A compelling addition to any art collection.",
    name: "Collection Of Twilight",
    link: "https://artogenia.com/product/6689840b26c9357a649dbb37",
    imgUrl:
      "https://coctljfofa.cloudimg.io/https://api.artogenia.com/files?id=66898409f0c040964a14d748&force_format=webp&q=20&optipress=3",
  },
  {
    artist: "Reza Khalili",
    price: 13000,
    mockup: "",
    description:
      "Measuring an impressive 100 by 100 cm, ‘Collection Of Twilight’ is a captivating contemporary piece rendered in acrylic on canvas. The artist skillfully employs a nuanced palette of dusky hues, creating a serene and ethereal atmosphere. The soft blending technique adds depth, while delicate brushwork captures the interplay of light and shadow. Created in 2023, this artwork invites contemplation and reflection, making it an evocative addition to any collection.",
    name: "Collection Of Twilight",
    link: "https://artogenia.com/product/6689856d26c9357a649dbb3a",
    imgUrl:
      "https://coctljfofa.cloudimg.io/https://api.artogenia.com/files?id=6689856af0c040964a14d74a&force_format=webp&q=20&optipress=3",
  },
  {
    artist: "Hamid Jafari",
    price: 12000,
    mockup: "",
    description:
      "This captivating artwork, titled “Bullfighting,” is a harmonious blend of colors and techniques. Measuring 100 by 120 cm, it exudes a sense of grandeur and depth. The monochromatic blue background provides a striking contrast to the intricate orange and white line work. Layers of texture and symbolism create a mesmerizing visual experience. The stylized central figures evoke movement and drama, making this piece a standout addition to any art collection. Crafted in 2014, its durability ensures it will be cherished for years to come.",
    name: "Bullfighting",
    link: "https://artogenia.com/product/6693e78a9dd0c44ecff538f2",
    imgUrl:
      "https://coctljfofa.cloudimg.io/https://api.artogenia.com/files?id=6693e785f0c040964a14d944&force_format=webp&q=20&optipress=3",
  },
  {
    artist: "Sara Farvardin",
    price: 15000,
    mockup: "",
    description:
      "“Iranian Illumination In The Style Of The Safavid Period” is a captivating mixed media artwork measuring 50 by 50 cm. Its palette is a harmonious blend of earthy tones, featuring shades of blue, gold, and red. The artist skillfully employs gouache and watercolor to create depth and subtlety in the colors. The pièce de résistance lies in the application of 23-karat gold, which adds a luxurious sheen and catches the light beautifully. The intricate Halqari pattern, embellished with 23-karat gold, showcases exceptional craftsmanship. This piece seamlessly marries traditional motifs with precious materials, making it a valuable addition to any discerning collector’s ensemble. Its durability and longevity ensure it will be cherished for generations to come.",
    name: "Iranian Illumination In The Style Of The Safavid Period",
    link: "https://artogenia.com/product/66974c119dd0c44ecff53920",
    imgUrl:
      "https://coctljfofa.cloudimg.io/https://api.artogenia.com/files?id=66974c10f0c040964a14d950&force_format=webp&q=20&optipress=3",
  },
  {
    artist: "Mohsen Ebadi",
    price: 6250,
    mockup: "",
    description:
      "Mohsen Ebadi's 2021 creation, a resplendent Surah Kawsar, marks a bold exploration of artistic heritage. This captivating artwork presents the short but poignant Surah in the rarely-seen Kufi Qirvani style, a script native to Northwest Africa. Ebadi's masterful execution breathes new life into this regional tradition, captivating viewers with its unique elegance. The artwork unfolds on a sheet of 41 x 49 cm, meticulously crafted by Dr. Veysi of Sanandaj, Iran. The specially starched paper provides a pristine surface for Ebadi's inscription, allowing the bold Kufi letters to stand out with clarity and precision. Beyond the script itself lies the true innovation of this piece. Ebadi collaborates with the renowned illuminator Mrs. Pouran Azarkaman to introduce a groundbreaking embellishment: gilding in the North African Mamluk style, using pure 24 karat gold. This artistic fusion merges elements from geographically distinct traditions, creating a visually stunning and historically significant piece. The subtle application of gouache and watercolor accents further enhances the composition, adding depth and luminescence. The most remarkable aspect of this Surah Kawsar lies in its unprecedented nature. Extensive research has revealed no existing historical, museum, or contemporary example of a gilded Kufi Qirvani script incorporating local gilding elements. Ebadi and Azarkaman's collaboration thus presents a groundbreaking addition to the vast tapestry of Islamic art. This Surah Kawsar transcends the boundaries of tradition, demonstrating the artistic potential of cross-cultural dialogue. It serves as a testament to Ebadi's vision and skill, pushing the boundaries of Islamic calligraphy while preserving the essence of these rich artistic heritages",
    name: "A River of Mercy: Surah Kawsar in Kufi Qirvani Majesty",
    link: "https://artogenia.com/product/668e8f195b510830164d1c4b",
    imgUrl:
      "https://coctljfofa.cloudimg.io/https://api.artogenia.com/files?id=668e8f18f0c040964a14d84c&force_format=webp&q=20&optipress=3",
  },
  {
    artist: "Yashar Malfouzi",
    price: 11250,
    mockup: "",
    description:
      "A Tapestry of Tranquility: The Zil-ul-Sultan's Vase Mihrab Rug The Zil-ul-Sultan's Vase Mihrab Rug is a stunning example of artistic heritage, hailing from the famed rug-weaving city of Tabriz, Iran. This singular piece, measuring 100 by 150 centimeters, transcends the realm of mere floor covering, transforming into a visual tapestry rich in symbolism and exquisite craftsmanship. Woven entirely by hand, the rug boasts an impressive knot count of 50 knots per 7cm. This meticulous technique, using a symmetrical Turkish knot, translates into a breathtaking level of detail and exceptional durability. Further elevating its value are the luxurious materials employed. Both the warp and weft threads are crafted from pure silk, lending the rug a captivating sheen and a touch of unparalleled softness. The central motif of the Zil-ul-Sultan's Vase Mihrab Rug is a vibrant bouquet nestled within a secure mihrab. The mihrab, a prayer niche traditionally found in mosques, takes on a symbolic meaning here, representing a space of peace and sanctuary. The choice of a turquoise hue for the mihrab further reinforces this sentiment, as turquoise is often associated with tranquility and protection. The vase itself overflows with colorful flowers, adding a touch of life and vibrancy to the composition. This interplay of symbolism and aesthetics creates a visually captivating experience, inviting the viewer to contemplate themes of peace and devotion. The Zil-ul-Sultan's Vase Mihrab Rug stands as a testament to the enduring legacy of Tabriz rug weaving. Every element, from the meticulous knotting to the symbolic color palette, speaks volumes about the artistry and skill passed down through generations of weavers. This singular piece is more than just a rug; it's a window into Persian cultural heritage and a true masterpiece of textile art.",
    name: "The Zil-ul-Sultan's Vase Mihrab",
    link: "https://artogenia.com/product/668e5a245594d36ebbc74090",
    imgUrl:
      "https://coctljfofa.cloudimg.io/https://api.artogenia.com/files?id=66e0236f0c63be0b71776582&force_format=webp&q=20&optipress=3",
  },
  {
    artist: "Yashar Malfouzi",
    price: 22500,
    mockup: "",
    description:
      "The Tree of Life Carpet The motif of the Tree of Life rug show an old tree which the deer protect the roots of it and in the security of the tree, a safe shelter has been made for nests and for the fertility of animal and birds on which they have built their nests and laid eggs. Simorgh birds are dancing and adore each other in this heavenly garden, feeling ecstatic of spring and its flowers. On this carpet all the animals are drawn in pairs, males and females, which depict the symbol of love and its outcome. This rug is in the dimensions of 129 * 200 cm It is woven with silk warp, silk flower and asymmetric knot and with the knot density of 50 Raj (approximately 640.000 knots per m2) in all over design without repetition in the field. The rug took eight months to weave and four months of embossing, which was done entirely manually and with a variety of artisan scissors.",
    name: "The Tree of Life Carpet",
    link: "https://artogenia.com/product/66895ef326c9357a649dbb1f",
    imgUrl:
      "https://coctljfofa.cloudimg.io/https://api.artogenia.com/files?id=66895ef2f0c040964a14d728&force_format=webp&q=20&optipress=3",
  },
  {
    artist: "Yashar Malfouzi",
    price: 37500,
    mockup: "",
    description:
      "The Navy Blue Flower Mihrab (Prayer Niche) The beautiful Flower Mihrab (Prayer Niche) carpet is one of the unique works of Malfouzi which its warp and the flowers are natural silk. This carpet is designed in half, illustrates a vase filled with flowers on a columnar Mihrab (Prayer Niche). In the beautiful color scheme of the field, the navy blue color and the jujube red on the margin is come into view. The column on this rug, a symbol of perseverance and solidity, has raised a garden full of flowers under a Mihrab dome and caused growing blossoms in this secure sanctuary. On this rug, all motifs are manually and traditionally polished and embossed. The dimensions of the rug are 140 x 220 cm with an asymmetric Persian knot. This rug was sold to a collector in Qatar. The weaving time of this rug 14 months and a period of 5 months of embossment using completely handcrafted tools and various scissors.",
    name: "The Navy Blue Flower Mihrab (Prayer Niche)",
    link: "https://artogenia.com/product/6689697a26c9357a649dbb2d",
    imgUrl:
      "https://coctljfofa.cloudimg.io/https://api.artogenia.com/files?id=66e020ea0c63be0b7177657e&force_format=webp&q=20&optipress=3",
  },
];

export { ArtData };
